.downContainer {
	position: absolute;
	width: 100%;
	max-width: 1000px;
	z-index: 1;
	background-color: #7a7373;
	color: white;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 50px;
	top: 4px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	border-radius: 4px;
}
